<template>
  <div
    class="profile"
  >
    <appBarMobile
      color="primary"
      show-back-button
      :show-nav-icon="false"
      extended
      extension-height="90px"
      title=""
      :reset-selection="backToDirectory"
    >
      <template
        v-if="userProfile != null"
        slot="extension"
      >
        <v-card
          color="primary"
          flat
        >
          <div
            class="d-flex pa-2"
          >
            <v-avatar
              class="avatar"
              size="56"
            >
              {{ userProfile.first_name ? userProfile.first_name.charAt(0):'' }}
            </v-avatar>
            <div
              class="d-flex self-align-start flex-column"
            >
              <span
                class="pl-4"
                style="font-size:24px;line-height:30px"
                role="heading"
                aria-level="1"
              >
                {{ userProfile.title ? (userProfile.title+' ') : userProfile.person_title ? userProfile.person_title+' ':'' }}{{ userProfile.first_name +' '+ userProfile.last_name }}
              </span>
              <span
                style="display:block;font-size:14px;line-height:20px"
                class="pl-4"
              >{{ this.profileInfo && this.profileInfo.length > 0 && this.profileInfo[0].det_position_title ? this.profileInfo[0].det_position_title : this.profileInfo && this.profileInfo.length > 0 && this.profileInfo[0].position_title ? this.profileInfo[0].position_title : this.userProfile && this.userProfile.position_title ? this.userProfile.position_title : '' }}</span>
            </div>
          </div>
        </v-card>
      </template>
    </appBarMobile>
    <Profile
      v-if="profile != null"
      class="ml-n4 mt-n4"
      :profile="profile"
      :detail="profileInfo"
    />
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import appBarMobile from "@/components/appBarMobile";
  import Profile from "@/components/Profile";
  export default {
    components: {
      appBarMobile,
      Profile
    },
    computed: {
      ...mapGetters({
        isLoading: 'isLoading'
      }),
      profileInfo(){
        let directory = this.$store.state.directoryModule.directory;
        if(this.profile && this.profile.det_user_id != null){
        return directory.filter(person => {
        if(person.det_user_id != null && this.profile.det_user_id != null){
         return person.det_user_id.toLowerCase() == this.profile.det_user_id.toLowerCase();
        }
        });
        }else{
          return null;
        }
      },
      userProfile(){
        return this.profile ? this.profile :null;
      },
      userInfo(){
        return this.profileInfo && this.profileInfo.length > 0 ? this.profileInfo[0] : null;
      }
    },
    created() {
        let user_id = "";
        if(this.$route.params.user_id){
          user_id = this.$route.params.user_id;
        }
        this.$store.dispatch("fetchProfile",user_id).then(()=>{
          this.profile = this.$store.state.directoryModule.profile;
          if(this.profile && !this.profile.first_name){
            this.$router.go(-1);
          }
        });
    },
    data(){
      return {
        profile : null
      }
    },
    methods:{
      backToDirectory(){
        this.$router.push('/directory/search');
      }
    }
  }
</script>

<style scoped>
.avatar{
  background-color:#C8DCF0;
  color:#041E42;
  font-size: 34px;
}
</style>