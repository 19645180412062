<template>
  <div
    class="d-flex flex-column flex-start profile"
    v-if="dataAvailable"
  >
    <v-card flat>
      <v-card-title class="pb-2">
        Contact details
      </v-card-title>
      <v-card-text class="pb-0">
        <div 
          v-if="this.userProfile && this.userProfile.preferredGivenName"
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            role="img"
            aria-label="person icon"
            class="align-self-baseline pt-1"
          >
            person
          </v-icon>
          <div
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4"
              style="font-size:16px;word-break: break-all"
              tabindex="0"
            >
              {{ this.userProfile.preferredGivenName }}
            </span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Preferred First Name</span>
          </div>
        </div>
        <div 
          v-if="this.contactDetails && this.contactDetails.email"
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            class="align-self-baseline pt-1"
            role="img"
          >
            email
          </v-icon>
          <div
            role=""
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4"
              aria-label="double tap to compose email"
              style="font-size:16px;word-break: break-all"
            >
              <a
                href="#"
                @click="mailTo(contactDetails.email)"
                tabindex="0"
                style="font-weight:bold;text-decoration:none;"
              >{{ this.contactDetails.email }}</a>
            </span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Email address</span>
          </div>
        </div>

        <div 
          v-if="this.contactDetails && this.contactDetails.mobile"
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            role="img"
            class="align-self-baseline pt-1"
          >
            smartphone
          </v-icon>
          <div
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4"
              style="font-size:16px"
              aria-label="double tap to open in keypad"
              @click="tel(contactDetails.mobile)"
              tabindex="0"
            >
              <a
                href="#"
                style="font-weight:bold;text-decoration:none;"
              >{{ this.contactDetails.mobile }}</a>
            </span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Mobile Number</span>
          </div>
        </div>
        <div 
          v-if="this.contactDetails && this.contactDetails.phone"
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            class="align-self-baseline pt-1"
            role="img"
          >
            phone
          </v-icon>
          <div
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4"
              style="font-size:16px"
              tabindex="0"
              aria-label="double tap to open phone in keypad"
              @click="tel(contactDetails.phone)"
            >
              <a
                href="#"
                style="font-weight:bold;text-decoration:none;"
              >{{ this.contactDetails.phone }}</a>
            </span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Phone Number</span>
          </div>
        </div>
        <div
          v-if="this.contactDetails.physical_site"
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            class="align-self-baseline pt-1"
            role="img"
          >
            apartment
          </v-icon>
          <div
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4"
              style="font-size:16px"
            >
              {{ this.contactDetails.physical_site }}</span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Physical Site</span>
          </div>
        </div>
        <div
          v-if="this.userDetails && this.userDetails.organizational_unit"
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            class="align-self-baseline pt-1"
            role="img"
          >
            school
          </v-icon>
          <div
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4"
              style="font-size:16px"
            >
              {{ this.userDetails.organizational_unit }}</span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Org Unit</span>
          </div>
        </div>
        <div 
          v-if="(this.contactDetails.street_address || this.contactDetails.locality || this.contactDetails.postcode)"
          class="d-flex pa-2"
        >
          <v-icon
            role="img"
            size="20"
            class="align-self-baseline pt-1"
          >
            location_on
          </v-icon>
          <div
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4"
              @click="map(contactDetails.street_address+', '+ contactDetails.locality+' '+ contactDetails.postcode)"
              tabindex="0"
              style="font-size:16px"
              aria-label="double tap to open address in maps"
            > 
              <a
                href="#"
                
                style="font-weight:bold;text-decoration:none;"
              >{{ (this.contactDetails.street_address ? this.contactDetails.street_address + (this.contactDetails.locality || this.contactDetails.postCode ? ', ' : '') : '') + (this.contactDetails.locality ? this.contactDetails.locality : '') + ' '+ (this.contactDetails.postcode ? this.contactDetails.postcode :'') }}</a>
            </span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Address</span>
          </div>
        </div>
        <div 
          v-if="this.contactDetails && this.contactDetails.det_user_id"
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            class="align-self-baseline pt-1"
            role="img"
          >
            account_circle
          </v-icon>
          <div 
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4"
              style="font-size:16px"
            >
              {{ this.contactDetails.det_user_id }}
            </span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Det User ID</span>
          </div>
        </div>
      </v-card-text>
      <v-card-title
        v-if="this.userDetails && this.userDetails.assistant"
        class="pt-0"
      >
        Assistant details
      </v-card-title>
      <v-card-text v-if="this.userDetails && this.userDetails.assistant">
        <div
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            role="img"
            class="align-self-baseline pt-0"
          >
            people
          </v-icon>
          <div
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4 site"
              style="font-size:16px"
            >
              {{ this.userDetails.first_name }}</span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Full name</span>
          </div>
        </div>

        <div
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            role="img"
            class="align-self-baseline pt-0"
          >
            email
          </v-icon>
          <div
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4 site"
              style="font-size:16px"
            >
              {{ this.userDetails.email }}</span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Email address</span>
          </div>
        </div>
        <div
          role=""
          class="d-flex pa-2"
        >
          <v-icon
            size="20"
            class="align-self-baseline pt-0"
            role="img"
          >
            phone
          </v-icon>
          <div
            class="d-flex self-align-start flex-column"
          >
            <span
              class="pl-4 site"
              style="font-size:16px"
            >
              {{ this.userDetails.mobile }}</span>
            <span
              style="font-size:12px"
              class="pl-4 pt-1 altcontent"
            >
              Contact number</span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name:"Profile",
  props:{
    profile:{
      type: Object,
      default: function(){ return {}}
    },
    detail: {
      type: Array,
      default: function(){ return []}
    }
  },
  computed:{
    userProfile(){
      return this.profile;
    },
    userDetails(){
      return this.detail && this.detail.length > 0 ? this.detail[0] : null;
    },
    dataAvailable(){
      return this.userProfile != null && this.userDetails != null;
    },
    contactDetails(){
      let contactDetails = {
        email:'',
        phone:'',
        det_user_id:'',
        mobile:'',
        street_address:'',
        locality:'',
        postcode:'',
        physical_site:''
        };
      this.userProfile.identities.forEach((identityObj)=>{
        if(identityObj.type && identityObj.type.toLowerCase() == "detuserid"){
          contactDetails.det_user_id = identityObj.value;
        }
      });
      this.userProfile.contactDetails.forEach(contactObj =>{
          if(contactObj.contactDetailType && contactObj.contactDetailType.toLowerCase()=="email" && contactObj.type.toLowerCase() =="work"){
            contactDetails.email = contactObj.address;
          }
          if(contactObj.contactDetailType && contactObj.contactDetailType.toLowerCase()=="phone" && contactObj.type.toLowerCase()=="main" ){
            contactDetails.mobile = contactObj.number;
          }
          if(contactObj.contactDetailType && contactObj.contactDetailType.toLowerCase()=="phone" && contactObj.type.toLowerCase()=="work"  ){
            contactDetails.phone = contactObj.number;
          }
          if(contactObj.contactDetailType && contactObj.contactDetailType.toLowerCase()=="address" && contactObj.type.toLowerCase()=="business" ){
            contactDetails.physical_site = contactObj.site ? contactObj.site : '';
            contactDetails.street_address = contactObj.streetAddress ? contactObj.streetAddress : '';
            contactDetails.locality = contactObj.locality ? contactObj.locality : '';
            contactDetails.postcode = contactObj.postcode ? contactObj.postcode : '';
            contactDetails.organizational_unit = contactObj.organizational_unit ? contactObj.organizational_unit : '';
          }
      });
      return contactDetails;
    }
  },
  methods:{
    mailTo(email){
      // eslint-disable-next-line
      if(NativeJS){
        // eslint-disable-next-line
        NativeJS.mailTo(email);
      }
    },
    tel(phone){
      if(phone){
        phone = phone.replace(/ /g,"");
      }
      // eslint-disable-next-line
      if(NativeJS){
        // eslint-disable-next-line
        NativeJS.tel(phone);
      }
    },
    map(address){
      // eslint-disable-next-line
      if(NativeJS){
        // eslint-disable-next-line
        NativeJS.maps(address);
      }
    }
  }
};
</script>

<style scoped>
.v-icon{
  color:#6D7079;
}
::v-deep .v-card__title,::v-deep .v-card__text{
  color: #121212 !important;
}
a,.site{
  color:#407EC9;
}
.altcontent{
  color:#6D7079;
}

</style>